@import 'src/index.scss';

header{
  padding: 10px 20px;
  height: 40px;
  width: 100vw;
  top: 0px;
  position: fixed;
  h1{
    font-size: 40px;
    margin: 0px;
    letter-spacing: 3px;
  }
}

.footerBuffer{
  height: 50px;
}

footer{
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 10px 20px;
  height: 40px;
  width: calc(100vw - 40px);
  text-align: right;
  a{
    margin-left: 15px;
  }
}