@import '/fonts/fonts.scss';

// colors
$BrandWhite: #ffffff;
$BrandLightGrey: #eaeaea;
$BrandDarkGrey: #3d3d3d;
$BrandLightBlue: #e3f9ff;

// Color
.brandwhite-color{
  color: $BrandWhite;
}
.brandlightgrey-color{
  color: $BrandLightGrey;
}
.branddarkgrey-color{
  color: $BrandDarkGrey;
}

// Background
.brandwhite-bg{
  background-color: $BrandWhite;
}
.brandlightgrey-bg{
  background-color: $BrandLightGrey;
}
.branddarkgrey-bg{
  background-color: $BrandDarkGrey;
}

$defaultPadding: 10px 20px;
.defaultPadding{
  padding: $defaultPadding;
}

// fonts
h1, h2, h3, h4, h5, h6{
  font-family: 'Optician Sans Regular', sans-serif;
  color: $BrandDarkGrey;
}

p, a, ul, li, span{
  font-family: 'Avenir', sans-serif;
  color: $BrandDarkGrey;
}

body {
  background-color: $BrandWhite;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
